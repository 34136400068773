import React from 'react'

class UserModal extends React.Component {
  render(){
    return(
      <div>
        <div className="row">
          <div className="col-sm-4">
            <label htmlFor="user-FirstName">First Name</label>
            <div className="user-field" id="user-FirstName">{this.props.model.first_name}</div>
          </div>
          <div className="col-sm-4">
            <label htmlFor="user-FirstName">Last Name</label>
            <div className="user-field" id="user-LastName">{this.props.model.last_name}</div>
          </div>
          <div className="col-sm-4">
            <label htmlFor="user-Email">Email</label>
            <div className="user-field" id="user-Email">{this.props.model.email}</div>
          </div>
        </div>
        <br/>
        <div className="row">
          <div className="col-sm-4">
            <label htmlFor="user-Role">Role</label>
            <div className="user-field" id="user-Role">{this.props.model.role}</div>
          </div>
          <div className="col-sm-4">
            <label htmlFor="user-Status">Status</label>
            <div className="user-field" id="user-Status">{this.props.model.status}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default UserModal;

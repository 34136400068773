import React from 'react'

class EventModal extends React.Component {

    render() {
        let modal = <GenericEventModal model={this.props.model}/>;

        if (this.props.model.type == 'cce') {
          modal = <UCCEEventModal model={this.props.model}/>;
        } else if (this.props.model.type == 'cvp') {
            modal = <CVPEventModal model={this.props.model}/>;
        }

        return modal;
    }
}

class GenericEventModal extends React.Component {
  render(){
      return(
        <div>

          <div className="row">
              <div className="col-sm-12">
                  <label htmlFor="event-AlarmName">Alarm Name</label>
                  <div className="event-field" id="event-AlarmName">{this.props.model.correlation_id}</div>
              </div>
          </div>  

          <div className="row">
              <div className="col-sm-6">
                  <label htmlFor="event-Timestamp">Timestamp</label>
                  <div className="event-field" id="event-Timestamp">{this.props.model.timestamp}</div>
              </div>
              <div className="col-sm-2">
                <label htmlFor="event-Severity">Severity</label>
                  <div className="event-field" id="event-Severity">{this.props.model.severity}</div>
              </div>
              <div className="col-sm-2">
                <label htmlFor="event-State">State</label>
                <div className="event-field" id="event-State">{this.props.model.state}</div>
              </div>
          </div>

          {!!this.props.model.installed_application || this.props.model.device ?
          <div>
            <h4 className="no-background" style={{textAlign: 'center'}}>
              <span>Source</span>
            </h4>

            <div className="row">
              <div className="col-sm-4">
                <label htmlFor="event-OriginatingSide">Type</label>
                <div className="event-field" id="event-OriginatingSide">{this.props.model.installed_application && this.props.model.installed_application.application_type.name}</div>
              </div>

              <div className="col-sm-4">
                <label htmlFor="event-Node">Instance</label>
                <div className="event-field" id="event-Node">{this.props.model.installed_application && this.props.model.installed_application.name}</div>
              </div>

              <div className="col-sm-4">
                <label htmlFor="event-Node">Device</label>
                <div className="event-field" id="event-Node">{this.props.model.device && this.props.model.device.host_name}</div>
              </div>
            </div>
          </div>
          : null}

          <h4 className="no-background" style={{textAlign: 'center'}}>
            <span>Details</span>
          </h4>

          <label htmlFor="event-Text">Message</label>
          <div className="event-field breakSpaces" id="event-Text">{this.props.model.message}</div>

          <label htmlFor="event-Description">Description</label>
          <div className="event-field" id="event-Description">{this.props.model.description || "None provided"}</div>

          <label htmlFor="event-Action">Recommended Action</label>
          <div className="event-field" id="event-Action">{this.props.model.recommended_action || "None provided"}</div>
        </div>
        
      )
  }
}

class UCCEEventModal extends React.Component {
    render(){
        return(
          <div>
            <div className="row">
              <div className="col-sm-12">
                  <label htmlFor="event-AlarmName">Alarm Name</label>
                  <div className="event-field" id="event-AlarmName">{this.props.model.correlation_id}</div>
              </div>
            </div>  

            <div className="row">
                <div className="col-sm-6">
                    <label htmlFor="event-Timestamp">Timestamp</label>
                    <div className="event-field" id="event-Timestamp">{this.props.model.payload['cccaEventTimestamp']}</div>
              </div>
              <div className="col-sm-2">
                <label htmlFor="event-Severity">Severity</label>
                  <div className="event-field" id="event-Severity">{this.props.model.payload['cccaEventSeverity']}</div>
              </div>
              <div className="col-sm-2">
                <label htmlFor="event-State">State</label>
                <div className="event-field" id="event-State">{this.props.model.payload['cccaEventState']}</div>
              </div>
            </div>

          <h4 className="no-background" style={{textAlign: 'center'}}>
            <span>Component</span>
          </h4>

          <div className="row">
            <div className="col-sm-4">
              <label htmlFor="event-Node">Name</label>
              <div className="event-field" id="event-Node">{this.props.model.payload['cccaEventOriginatingNode']}</div>
            </div>
            <div className="col-sm-2">
              <label htmlFor="event-OriginatingSide">Side</label>
              <div className="event-field" id="event-OriginatingSide">{this.props.model.payload['cccaEventOriginatingSide']}</div>
            </div>
            <div className="col-sm-3">
              <label htmlFor="event-NodeType">Node</label>
              <div className="event-field" id="event-NodeType">{this.props.model.payload['cccaEventOriginatingNodeType']}</div>
            </div>
            <div className="col-sm-3">
              <label htmlFor="event-OriginatingProcessName">Process</label>
              <div className="event-field" id="event-OriginatingProcessName">{this.props.model.payload['cccaEventOriginatingProcessName']}</div>
            </div>
          </div>

          <h4 className="no-background" style={{textAlign: 'center'}}>
            <span>Details</span>
          </h4>

          <label htmlFor="event-Text">Message</label>
          <div className="event-field breakSpaces" id="event-Text">{this.props.model.payload['cccaEventText']}</div>

          <label htmlFor="event-Description">Description</label>
          <div className="event-field" id="event-Description">{this.props.model.payload['description']}</div>

          <label htmlFor="event-Action">Recommended Action</label>
          <div className="event-field" id="event-Action">{this.props.model.payload['notification']['action']}</div>
        </div>
        )
    }
}


class CVPEventModal extends React.Component {
    render(){
        return(
            <div>

              <div className="row">
                <div className="col-sm-12">
                    <label htmlFor="event-AlarmName">Alarm Name</label>
                    <div className="event-field" id="event-AlarmName">{this.props.model.correlation_id}</div>
                </div>
              </div>  

              <div className="row">
                    <div className="col-sm-6">
                        <label htmlFor="event-Timestamp">Timestamp</label>
                        <div className="event-field" id="event-Timestamp">{this.props.model.payload['ccvpEventTimestamp']}</div>
                </div>
                <div className="col-sm-2">
                  <label htmlFor="event-Severity">Severity</label>
                    <div className="event-field" id="event-Severity">{this.props.model.payload['ccvpEventSeverity']}</div>
                </div>
                <div className="col-sm-2">
                  <label htmlFor="event-State">State</label>
                  <div className="event-field" id="event-State">{this.props.model.payload['ccvpEventState']}</div>
                </div>
              </div>

            <h4 className="no-background" style={{textAlign: 'center'}}>
              <span>Component</span>
            </h4>

            <div className="row">
              <div className="col-sm-6">
                <label htmlFor="event-Node">Hostname</label>
                <div className="event-field" id="event-Node">{this.props.model.payload['ccvpEventHostName']}</div>
              </div>
              <div className="col-sm-6">
                <label htmlFor="event-OriginatingSide">Application</label>
                <div className="event-field" id="event-OriginatingSide">{this.props.model.payload['ccvpEventAppName']}</div>
              </div>

            </div>

            <h4 className="no-background" style={{textAlign: 'center'}}>
              <span>Details</span>
            </h4>

            <label htmlFor="event-Text">Message</label>
            <div className="event-field breakSpaces" style={{wordBreak: 'break-all'}}
                id="event-Text">{this.props.model.payload['ccvpEventText']}</div>


        </div>
        )
    }
}

export default EventModal;

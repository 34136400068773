import React from 'react'

class AlarmModal extends React.Component {
  render(){
    return(
      <div>
        <div className="row">
          <div className="col-sm-3">
            <label htmlFor="alarm-Severity">Severity</label>
            <div className="alarm-field" id="alarm-Severity">{this.props.model.severity}</div>
          </div>
          <div className="col-sm-4">
            <label htmlFor="alarm-RaisedAt">Raised At</label>
            <div className="alarm-field" id="alarm-RaisedAt">{this.props.model.raised_at}</div>
          </div>
          <div className="col-sm-4">
            <label htmlFor="alarm-ClearedAt">Cleared At</label>
            <div className="alarm-field" id="alarm-ClearedAt">{this.props.model.cleared_at}</div>
          </div>
        </div>

        <h4 className="no-background" style={{textAlign: 'center'}}>
          <span>Details</span>
        </h4>

        <label htmlFor="alarm-Name">Name</label>
        <div className="alarm-field" id="alarm-Name">{this.props.model.name}</div>

        <br/>

        <label htmlFor="alarm-Text">Message</label>
        <div className="alarm-field" id="alarm-Text">{this.props.model.message}</div>
        
        <br/>

        <label htmlFor="alarm-Description">Description</label>
        <div className="alarm-field" id="alarm-Description">{this.props.model.description}</div>
      </div>
    );
  }
}

export default AlarmModal;

import React from 'react'
import {Button} from 'react-bootstrap'

class QueryModal extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            toAdd: [],
            toRemove: []
        }
    }

    handleAddMetric(){
        let url;
        let data = {metrics: this.state.toAdd};
        if(this.props.modalType === "Operating System Metrics"){
            url = "/operating_system_metrics/set_active";
            data = {...data, operating_system_id: this.props.model.metric_model.id}
        }
        if(this.props.modalType === "Device Model Metrics"){
            url = "/device_model_metrics/set_active";
            data = {...data, device_model_id: this.props.model.metric_model.id}
        }
        let self = this;
        $.ajax({
          method: 'POST',
          url: url,
          data: data
        }).then((response)=>{
            if(response.metric_model){
                self.setState({toAdd: [], toRemove: []}, function(){
                    renderVigilusModal(this.props.modalType, true, response, self.props.containerId);
                });
            }
        })
    }
    handleRemoveMetric(){
        let url;
        let data = {metrics: this.state.toRemove};
        if(this.props.modalType === "Operating System Metrics"){
            url = "/operating_system_metrics/set_inactive";
            data = {...data, operating_system_id: this.props.model.metric_model.id}
        }
        if(this.props.modalType === "Device Model Metrics"){
            url = "/device_model_metrics/set_inactive";
            data = {...data, device_model_id: this.props.model.metric_model.id}
        }
        let self = this;
        $.ajax({
            method: 'DELETE',
            url: url,
            data: data
        }).then((response)=>{
            if(response.metric_model){
                self.setState({toRemove: [], toAdd: []},function(){
                    renderVigilusModal(this.props.modalType, true, response, self.props.containerId);
                });
            }
        })
    }
    handleSelectActive(index, event){
        console.log('Select Active metric');
        let newToRemove = this.state.toRemove;
        if(newToRemove.includes(index)){
            newToRemove.splice(newToRemove.indexOf(index), 1);
        }else{
            newToRemove.push(index);
        }
        this.setState({toRemove: newToRemove});
    }
    handleSelectInactive(index, event){
        console.log('Select Active metric');
        let newToAdd = this.state.toAdd;
        if(newToAdd.includes(index)){
            newToAdd.splice(newToAdd.indexOf(index), 1);
        }else{
            newToAdd.push(index);
        }
        this.setState({toAdd: newToAdd});
    }

    render() {
        var self = this;
        let inactiveMetricsList = this.props.model.all_queries;
        if(this.props.model.metrics.length > 0){
            inactiveMetricsList = inactiveMetricsList.filter((currentMetric) => {
                for(let i = 0; i < this.props.model.metrics.length; i++){
                    if(this.props.model.metrics[i].id === currentMetric.id){
                        return false;
                    }
                }
                return true;
            });
        }
        let inactiveMetrics = inactiveMetricsList.map((metric, index) => {
            let checkboxState = this.state.toAdd.includes(metric.id);
            return(
                [
                    <input type="checkbox" onChange={this.handleSelectInactive.bind(this, metric.id)} key={index} checked={checkboxState}/>,
                    <label style={{marginLeft: "10px"}}>{metric.name}</label>,
                    <br/>
                ]
            )
        });
        let activeMetrics = this.props.model.metrics.map((metric, index) => {
            let checkboxState = this.state.toRemove.includes(metric.id);
            return(
                [
                    <input type="checkbox" onChange={this.handleSelectActive.bind(this, metric.id)} key={index} checked={checkboxState} />,
                    <label style={{marginLeft: "10px"}}>{metric.name}</label>,
                    <br/>
                ]
            )
        });

        return (
            <div className="row">
                <div className="col-sm-5">
                    <h2>Inactive Metrics</h2>
                    {inactiveMetrics}
                </div>
                <div className="col-sm-2">
                    <br/>
                    <Button
                        bsStyle="info"
                        bsSize="small"
                        onClick={this.handleAddMetric.bind(this)}
                    >
                        <i className="fa fa-plus"></i>
                    </Button>

                    <Button
                        bsStyle="info"
                        bsSize="small"
                        onClick={this.handleRemoveMetric.bind(this)}
                    >
                        <i className="fa fa-minus"></i>
                    </Button>
                </div>
                <div className="col-sm-5">
                    <h2>Active Metrics</h2>
                    {activeMetrics}
                </div>
            </div>
        )
    }
}
export default QueryModal;

import React from 'react'
import ReactDOM from 'react-dom'
import {Modal} from 'react-bootstrap'
import {Button} from 'react-bootstrap'
import EventModal from './modals/event_modal'
import QueryModal from './modals/query_modal'
import UCCEStatusModal from './modals/query_modal'
import AlarmModal from './modals/alarm_modal'
import UserModal from './modals/user_modal'
export default class VigilusModal extends React.Component {
    close(){
        if (this.props.onClose) {
            this.props.onClose();
            return;
        }

        let container = document.getElementById('vigilus-modal');
        ReactDOM.render(<VigilusModal {...this.props} open={false} />, container);
    }
    render(){
       let body = "";
       switch(this.props.modalType){
           case 'Events':
               body = <EventModal {...this.props} open={this.props.open} />;
               break;
           case 'Device Model Metrics':
               body = <QueryModal {...this.props} open={this.props.open} />;
               break;
           case 'Operating System Metrics':
               body = <QueryModal {...this.props} open={this.props.open} />;
               break;
           case 'UCCE Status':
               body = <UCCEStatusModal {...this.props} open={this.props.open} />;
               break;
           case 'Alarms':
               body = <AlarmModal {...this.props} open={this.props.open} />;
               break;
           case 'User':
               body = <UserModal {...this.props} open={this.props.open} />;
               break;
       }
       if (this.props.component) {
           body = this.props.component;
       }
       return(
           <div style={{display: 'inline'}}>
               <Modal bsSize={this.props.size}
                show={this.props.open} onHide={this.close.bind(this)} >
                   <Modal.Header closeButton>
                       <Modal.Title>{this.props.title}</Modal.Title>
                   </Modal.Header>
                   <Modal.Body>
                       {body}
                   </Modal.Body>
                   <Modal.Footer>
                       <Button
                           bsStyle="default"
                           className="pull-left"
                           onClick={this.close.bind(this)}
                       >
                           Close
                       </Button>
                   </Modal.Footer>
               </Modal>
           </div>
       )
    }
}

window.renderInVigilusModal = function(component, title, open, model, size) {
    let container = document.getElementById("vigilus-modal");
    ReactDOM.render(<VigilusModal component={component} title={title} model={model} open={open} size={size} containerId={"vigilus-modal"}/>, container);
}

window.renderVigilusModal = function(type, open, model, containerId){
    let container = document.getElementById(containerId);
    ReactDOM.render(<VigilusModal modalType={type} title={type} model={model} open={open} containerId={containerId}/>, container);
};
